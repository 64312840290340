<template>
    <div>
        <v-container
            id="announcement"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="6" cols="12" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Profile Management</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedMinistryPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="profileItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col cols="12" md="3">
                        <v-card outlined height="46rem">
                            <v-card-title
                                class="secondary--text font-weight-bold"
                                >Choose your recipients
                            </v-card-title>
                            <v-card-subtitle
                                >Send to everyone. or use an audience to target
                                the right users.
                            </v-card-subtitle>

                            <v-card-text>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="d-flex justify-space-between align-center"
                                    >
                                        <v-radio-group v-model="selectedType">
                                            <div
                                                class="d-flex justify-space-between align-center"
                                            >
                                                <v-radio
                                                    label="Everyone"
                                                    value="all"
                                                ></v-radio>
                                                <span
                                                    class="body-2 font-weight-bold"
                                                    >{{
                                                        user.pagination.total ||
                                                        0
                                                    }}</span
                                                >
                                            </div>
                                            <div class="my-2"></div>
                                            <div>
                                                <div
                                                    class="d-flex justify-space-between align-center mb-5"
                                                >
                                                    <v-radio
                                                        label="Users"
                                                        value="users"
                                                        class="mr-5"
                                                    ></v-radio>
                                                    <v-spacer></v-spacer>
                                                    <v-text-field
                                                        hide-details
                                                        solo
                                                        rounded
                                                        placeholder="Search"
                                                        dense
                                                        prepend-inner-icon="mdi-magnify"
                                                        v-model="user.search"
                                                        autofocus
                                                        clearable
                                                        @click:clear="
                                                            user.search = null
                                                        "
                                                        :disabled="
                                                            selectedType ===
                                                            'all'
                                                        "
                                                        class="recipient-search-input"
                                                    ></v-text-field>
                                                </div>
                                                <v-list
                                                    rounded
                                                    dense
                                                    class="overflow-y-auto"
                                                    color="#F1F1F1"
                                                    height="30rem"
                                                    style="
                                                        background-color: #f1eeee;
                                                        overflow-y: auto;
                                                    "
                                                    :disabled="
                                                        selectedType === 'all'
                                                    "
                                                >
                                                    <v-subheader
                                                        v-if="user.search"
                                                        >Search results for:
                                                        {{
                                                            user.search
                                                        }}</v-subheader
                                                    >
                                                    <template
                                                        v-for="(
                                                            user, index
                                                        ) in user.items"
                                                    >
                                                        <v-list-item
                                                            :key="index"
                                                        >
                                                            <template
                                                                v-slot:default
                                                            >
                                                                <v-list-item-action>
                                                                    <v-checkbox
                                                                        color="primary"
                                                                        dense
                                                                        :value="
                                                                            user.id
                                                                        "
                                                                        v-model="
                                                                            form.user_ids
                                                                        "
                                                                        :disabled="
                                                                            selectedType ===
                                                                            'all'
                                                                        "
                                                                    ></v-checkbox>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title
                                                                        >{{
                                                                            user.first_name
                                                                        }}{{
                                                                            user.last_name
                                                                                ? ` ${user.last_name}`
                                                                                : ""
                                                                        }}</v-list-item-title
                                                                    >
                                                                    <v-list-item-subtitle
                                                                        >{{
                                                                            user.email
                                                                        }}</v-list-item-subtitle
                                                                    >
                                                                </v-list-item-content>
                                                            </template>
                                                        </v-list-item>
                                                    </template>

                                                    <base-infinite-scroll
                                                        :action="getUsers"
                                                        :identifier="infiniteId"
                                                        loading
                                                    ></base-infinite-scroll>
                                                </v-list>
                                            </div>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-card outlined height="46rem">
                            <v-card-title>
                                <span class="secondary--text font-weight-bold">
                                    {{
                                        operation.createMode
                                            ? "Write"
                                            : "Update"
                                    }}
                                    your message
                                </span>
                                <v-spacer></v-spacer>
                                <v-btn
                                    icon
                                    @click="resetForm"
                                    v-if="operation.editMode"
                                    ><v-icon>mdi-refresh</v-icon></v-btn
                                >
                            </v-card-title>
                            <v-card-subtitle
                                >The best campaigns use short and direct
                                messaging.
                            </v-card-subtitle>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" v-if="!!error">
                                        <v-alert tile type="error">
                                            {{ error }}
                                        </v-alert>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label required
                                            >Title</base-input-label
                                        >
                                        <v-text-field
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            hide-details
                                            v-model="form.title"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label required
                                            >Description</base-input-label
                                        >
                                        <v-textarea
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            hide-details
                                            v-model="form.description"
                                        ></v-textarea>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label required
                                            >Announce At</base-input-label
                                        >
                                        <base-datetime-picker
                                            solo
                                            rounded
                                            class="rounded-lg"
                                            hide-details
                                            :min="currentDate"
                                            v-model="form.announce_at"
                                        ></base-datetime-picker>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="pa-0">
                                <v-container fluid>
                                    <v-row
                                        align="center"
                                        justify="center"
                                        class="py-1 px-2"
                                    >
                                        <v-col md="2" cols="12" class="px-2">
                                            <v-btn
                                                block
                                                depressed
                                                dark
                                                color="#389877"
                                                rounded
                                                @click="onAnnounce"
                                                >Announce</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card
                            outlined
                            height="46rem"
                            class="announcement-list"
                        >
                            <v-card-title
                                class="secondary--text font-weight-bold"
                                >Announcements
                            </v-card-title>
                            <v-data-table
                                class="custom-table"
                                :headers="table.headers"
                                :loading="table.loading"
                                :items="table.items"
                                :server-items-length="table.pagination.total"
                                :items-per-page.sync="table.pagination.perPage"
                                :page.sync="table.pagination.page"
                                :footer-props="{
                                    'items-per-page-options':
                                        table.pagination.itemsPerPageOptions,
                                }"
                                hide-default-footer
                            >
                                <template v-slot:item.title="{ item }">
                                    <span class="text-capitalize">{{
                                        truncateText(item.title)
                                    }}</span>
                                </template>
                                <template v-slot:item.date="{ item }">
                                    <span>{{
                                        formatDate(item.announce_at)
                                    }}</span>
                                </template>

                                <template v-slot:item.status="{ item }">
                                    <v-chip
                                        :color="
                                            item.is_announced
                                                ? 'success'
                                                : 'warning'
                                        "
                                        >{{
                                            item.is_announced
                                                ? "Done"
                                                : "Pending"
                                        }}</v-chip
                                    >
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <div
                                        class="d-flex justify-center"
                                        v-if="
                                            operation.editMode &&
                                            item.id === announcementID
                                        "
                                    >
                                        <span
                                            class="caption grey--text font-italic"
                                            >Viewing</span
                                        >
                                    </div>

                                    <div
                                        class="d-flex justify-center align-center"
                                        v-else
                                    >
                                        <v-btn
                                            icon
                                            @click="
                                                setCurrentAnnouncement(item)
                                            "
                                            :disabled="item.is_announced === 1"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn
                                            icon
                                            :disabled="item.is_announced === 1"
                                            @click="openAlertDialog(item)"
                                        >
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                            <div class="announcement-list__pagination">
                                <custom-pagination
                                    :total-pages="table.pagination.lastPage"
                                    :current-page="table.pagination.currentPage"
                                    @change="onPaginationChange"
                                ></custom-pagination>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <base-alert-dialog
            :is-open.sync="alertDialog.open"
            :title="alertDialog.title"
            :description="alertDialog.description"
            :theme="alertDialog.theme"
            :loading="isDeleteStart"
            @onCancel="alertDialog.open = false"
            @onProceed="deleteAnnouncement"
        ></base-alert-dialog>
    </div>
</template>

<script>
import { GET_USERS } from "@/store/modules/user";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import BaseDatetimePicker from "@/components/Base/BaseDateTimePicker";
import BaseInfiniteScroll from "@/components/Base/BaseInfiniteScroll";
import {
    CREATE_ANNOUNCEMENT,
    DELETE_ANNOUNCEMENT,
    GET_ANNOUNCEMENTS,
    UPDATE_ANNOUNCEMENT,
} from "@/store/modules/announcement";
import helperUtility from "@/mixins/helper";
import CustomPagination from "@/components/Custom/CustomPagination";
import dateUtility from "@/mixins/date";
import BaseAlertDialog from "@/components/Base/BaseAlertDialog";

const defaultForm = {
    announce_by_user_id: null,
    title: null,
    description: null,
    announcement_type: null,
    is_public: true,
    user_ids: [],
    announce_at: null,
};

export default {
    name: "ministry-announcements",

    components: {
        BaseAlertDialog,
        CustomPagination,
        BaseInfiniteScroll,
        BaseDatetimePicker,
        BaseInputLabel,
    },

    data() {
        return {
            infiniteId: +new Date(),
            selectedType: "all",
            user: {
                items: [],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            table: {
                items: [],
                headers: [
                    { text: "Title", value: "title", sortable: false },
                    {
                        text: "Announce At",
                        value: "date",
                        sortable: false,
                    },
                    {
                        text: "Status",
                        value: "status",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        align: "right",
                        sortable: false,
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 8,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            selectedMinistryPage: "/ministry/announcements",
            profileItems: [
                {
                    text: "Users",
                    value: "/ministry/users",
                },
                {
                    text: "Groups",
                    value: "/ministry/groups",
                },
                {
                    text: "Requests",
                    value: "/ministry/requests",
                },
                {
                    text: "Announcements",
                    value: "/ministry/announcements",
                },
            ],
            form: Object.assign({}, defaultForm),
            error: null,
            announcementOperation: "create",
            announcementID: null,
            isCreateUpdateStart: false,
            isDeleteStart: false,
            alertDialog: {
                open: false,
                theme: "warning",
                title: null,
                description: null,
            },
        };
    },

    computed: {
        currentDate() {
            return dateUtility.currentDate();
        },

        operation() {
            return {
                createMode: this.announcementOperation === "create",
                editMode: this.announcementOperation === "edit",
            };
        },
    },

    watch: {
        selectedType(value) {
            if (value === "all") {
                this.form.user_ids = [];
            }
        },

        ["user.search"]: helperUtility.debounce(async function () {
            this.resetUsers();
        }, 500),

        ["table.pagination"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getAnnouncements();
            },
            deep: true,
        },
    },

    methods: {
        async getUsers($state) {
            const { page, perPage } = this.user.pagination;
            const payload = {
                page: page || 1,
                perPage: perPage || 10,
                search: this.user.search || null,
            };
            this.user.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_USERS,
                payload
            );
            if (success) {
                const items = data.data;
                if (items.length === this.user.pagination.perPage) {
                    this.user.pagination.total = data.meta.total || 0;
                    this.user.items = [...this.user.items, ...items];
                    this.user.pagination.page += 1;
                    this.user.loading = false;
                    $state.loaded();
                    return;
                }
                this.user.items = [...this.user.items, ...items];
                this.user.loading = false;
                $state.complete();
            }
        },

        async getAnnouncements() {
            const payload = {
                ...this.table.pagination,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_ANNOUNCEMENTS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onAnnounce() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },

        async onCreate() {
            const payload = {
                ...this.form,
                is_public: this.selectedType === "all",
                announce_at: dateUtility.toUTC(this.form.announce_at),
            };
            this.isCreateUpdateStart = true;
            const { success, message } = await this.$store.dispatch(
                CREATE_ANNOUNCEMENT,
                payload
            );
            if (success) {
                this.dialog = false;
                this.resetForm();
                await this.getAnnouncements();
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Announcement",
                    text: "Message was posted.",
                });
                return;
            }
            this.error = message;
            this.isCreateUpdateStart = false;
        },

        async onUpdate() {
            const payload = {
                announcementID: this.announcementID,
                ...this.form,
                is_public: this.selectedType === "all",
                announce_at: dateUtility.toUTC(this.form.announce_at),
            };
            this.isCreateUpdateStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_ANNOUNCEMENT,
                payload
            );
            if (success) {
                this.dialog = false;
                this.resetForm();
                await this.getAnnouncements();
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Announcement",
                    text: "Message was updated.",
                });
                return;
            }
            this.error = message;
            this.isCreateUpdateStart = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
        },

        changeView(to) {
            this.$router.push(to);
        },

        async initiate() {
            this.selectedMinistryPage = this.$route.path;
            await this.getAnnouncements();
        },

        setCurrentAnnouncement(announcement) {
            const {
                id,
                title,
                description,
                announce_to_users,
                announce_at,
                is_public,
            } = announcement;
            this.announcementOperation = "edit";
            this.announcementID = id;
            this.selectedType = is_public ? "all" : "users";
            this.form = Object.assign(
                {},
                {
                    title,
                    description,
                    announce_at: dateUtility.toLocalDate(announce_at),
                    user_ids:
                        this.selectedType === "users"
                            ? announce_to_users.map((user) => user.id)
                            : [],
                }
            );
        },

        resetUsers() {
            this.user.pagination.page = 1;
            this.user.items = [];
            this.infiniteId += 1;
        },

        resetForm() {
            this.announcementID = null;
            this.announcementOperation = "create";
            this.selectedType = "all";
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        formatDate(timestamp) {
            return dateUtility.formatTimestampToDescription(
                dateUtility.toLocalDate(timestamp)
            );
        },

        truncateText(text) {
            return helperUtility.truncateText(text, 10);
        },

        openAlertDialog(announcement) {
            const { id, title } = announcement;
            this.announcementID = id;
            this.alertDialog = Object.assign(
                {},
                {
                    ...this.alertDialog,
                    title: "Delete Announcement",
                    description: `Are you sure you want this ${title} announcement? Click proceed if you are sure.`,
                    open: true,
                }
            );
        },

        async deleteAnnouncement() {
            this.isDeleteStart = true;
            const { success } = await this.$store.dispatch(
                DELETE_ANNOUNCEMENT,
                this.announcementID
            );
            if (success) {
                this.alertDialog.open = false;
                this.isDeleteStart = false;
                await this.getAnnouncements();
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    duration: 5000,
                    title: "Announcement",
                    text: "Message was deleted.",
                });
                return;
            }
            this.isDeleteStart = false;
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}

.announcement-list {
    overflow: auto;

    &__pagination {
        padding: 1rem;
    }
}
</style>
