<template>
    <v-dialog v-model="display" :width="dialogWidth">
        <template v-slot:activator="{ on }">
            <v-text-field
                :color="color"
                v-bind="textFieldProps"
                :disabled="disabled"
                :loading="loading"
                :label="label"
                :value="formattedDatetime"
                :outlined="outlined"
                :solo="solo"
                :flat="flat"
                :hide-details="hideDetails"
                append-icon="mdi-calendar-clock"
                :class="customClass"
                clearable
                @click:clear="$emit('input', null)"
                v-on="on"
                readonly
            >
                <template v-slot:progress>
                    <slot name="progress">
                        <v-progress-linear
                            color="secondary"
                            indeterminate
                            absolute
                            height="2"
                        ></v-progress-linear>
                    </slot>
                </template>
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-tabs fixed-tabs color="secondary" v-model="activeTab">
                    <v-tab key="calendar">
                        <slot name="dateIcon">
                            <v-icon>mdi-calendar</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab key="timer" :disabled="dateSelected">
                        <slot name="timeIcon">
                            <v-icon>mdi-clock</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab-item key="calendar">
                        <v-date-picker
                            :color="color"
                            v-bind="datePickerProps"
                            @input="showTimePicker"
                            full-width
                            :min="min"
                            v-model="date"
                        ></v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <v-time-picker
                            :color="color"
                            ref="timer"
                            class="v-time-picker-custom"
                            v-bind="timePickerProps"
                            full-width
                            v-model="time"
                        ></v-time-picker>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="actions" :parent="this">
                    <v-btn :color="color" text @click="okHandler">{{
                        okText
                    }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, parse } from "date-fns";
import dateUtility from "@/mixins/date";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "Done";

export default {
    name: "base-datetime-picker",

    model: {
        prop: "datetime",
        event: "input",
    },

    props: {
        customClass: {
            type: String,
            default: "",
        },

        color: {
            type: String,
            default: "",
        },

        datetime: {
            type: [Date, String],
            default: null,
        },

        disabled: {
            type: Boolean,
        },

        outlined: {
            type: Boolean,
        },

        loading: {
            type: Boolean,
        },

        solo: {
            type: Boolean,
        },

        rounded: {
            type: Boolean,
        },

        flat: {
            type: Boolean,
        },

        hideDetails: {
            type: Boolean,
        },

        label: {
            type: String,
            default: "",
        },

        dialogWidth: {
            type: Number,
            default: DEFAULT_DIALOG_WIDTH,
        },

        dateFormat: {
            type: String,
            default: DEFAULT_DATE_FORMAT,
        },

        timeFormat: {
            type: String,
            default: "HH:mm:ss",
        },

        clearText: {
            type: String,
            default: DEFAULT_CLEAR_TEXT,
        },

        okText: {
            type: String,
            default: DEFAULT_OK_TEXT,
        },

        textFieldProps: {
            type: Object,
        },

        datePickerProps: {
            type: Object,
        },

        timePickerProps: {
            type: Object,
        },

        min: {
            type: String,
        },
    },

    data() {
        return {
            display: false,
            activeTab: 0,
            date: DEFAULT_DATE,
            time: DEFAULT_TIME,
        };
    },

    computed: {
        dateTimeFormat() {
            return this.dateFormat + " " + this.timeFormat;
        },

        defaultDateTimeFormat() {
            return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
        },

        formattedDatetime() {
            return this.selectedDatetime
                ? dateUtility.formatTimestampToDescription(
                      dateUtility.formatTimestampToStandardFormat(
                          this.selectedDatetime
                      )
                  )
                : "";
        },

        selectedDatetime() {
            if (this.date && this.time) {
                let datetimeString = this.date + " " + this.time;
                if (this.time.length === 5) {
                    datetimeString += ":00";
                }
                const datetime = parse(
                    datetimeString,
                    this.defaultDateTimeFormat,
                    new Date()
                );
                return dateUtility.formatTimestampToStandardFormat(datetime);
            } else {
                return null;
            }
        },
        dateSelected() {
            return !this.date;
        },
    },

    watch: {
        datetime(value) {
            if (value) {
                this.init();
                return;
            }
            this.clearHandler();
        },
    },

    methods: {
        init() {
            if (!this.datetime) {
                return;
            }
            let initDateTime;
            if (this.datetime instanceof Date) {
                initDateTime = this.datetime;
            } else if (
                typeof this.datetime === "string" ||
                this.datetime instanceof String
            ) {
                // see https://stackoverflow.com/a/9436948
                initDateTime = parse(
                    this.datetime,
                    this.dateTimeFormat,
                    new Date()
                );
            }
            this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
            this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
        },

        okHandler() {
            this.resetPicker();
            this.$emit("input", this.selectedDatetime);
        },

        clearHandler() {
            this.resetPicker();
            this.date = DEFAULT_DATE;
            this.time = DEFAULT_TIME;
            this.$emit("input", null);
        },

        resetPicker() {
            this.display = false;
            this.activeTab = 0;
            if (this.$refs.timer) {
                this.$refs.timer.selectingHour = true;
            }
        },

        showTimePicker() {
            this.activeTab = 1;
        },
    },

    mounted() {
        this.init();
    },
};
</script>
